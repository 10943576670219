<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
  },
  additionalClasses: {
    type: String,
    default: "",
  },
  variant: {
    type: String,
    default: "no_outline",
  },
  toLowerCase: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const mergedClasses = computed(() => {
  const baseClasses = "!rounded-xs !ring-0 !bg-white";
  return `${baseClasses} ${props.additionalClasses}`.trim();
});
const emit = defineEmits(["update:modelValue"]);
// Method to handle input event
const handleInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (props.toLowerCase) {
    // Only transform to lowercase if `toLowerCase` prop is true
    emit("update:modelValue", value.toLowerCase());
  } else {
    emit("update:modelValue", value);
  }
};
</script>

<template>
  <UInput
    color="default"
    :variant="variant"
    size="xl"
    :type="type"
    :placeholder="placeholder"
    :model-value="modelValue"
    :input-class="mergedClasses"
    :disabled="disabled"
    @input="handleInput"
  />
</template>

<style scoped></style>
